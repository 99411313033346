<template>
  <div>
    <v-form ref="couponForm" @submit.prevent="handleSubmitForm">
      <v-row>
        <v-col cols="12" class="d-flex align-center">
          <v-btn icon to="/coupons" class="mr-3" exact>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h2>Ajouter un coupon de réduction</h2>
        </v-col>
        <v-col cols="12" v-if="error">
          <v-alert type="error"> Veuillez vérifier tous les champs. </v-alert>
        </v-col>
        <v-col cols="12" md="9">
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1"
                @click="e1 = 1"
                style="cursor: pointer"
              >
                Code
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 2"
                step="2"
                @click="e1 = 2"
                style="cursor: pointer"
              >
                Type
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 3"
                step="3"
                @click="e1 = 3"
                style="cursor: pointer"
              >
                Date
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 4"
                step="4"
                @click="e1 = 4"
                style="cursor: pointer"
              >
                Conditions
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 5"
                step="5"
                @click="e1 = 5"
                style="cursor: pointer"
              >
                Annulation
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 6"
                step="6"
                @click="e1 = 6"
                style="cursor: pointer"
              >
                Rayon
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="e1 > 7"
                step="7"
                @click="e1 = 7"
                style="cursor: pointer"
              >
                Services
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-alert type="info">
                  <strong>Commençons par définir le code promotionnel.</strong>
                </v-alert>

                <v-card tile flat>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <h3>Code de réduction</h3>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          Définissez le code de réduction que le client devra
                          entrer lors de la prise de rendez-vous. <br />
                          Appliquer le coupon automatiquement permettra aux
                          clients de ne pas avoir à saisir le code de réduction
                          manuellement.
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          :value="code"
                          hide-details
                          outlined
                          label="Code de réduction"
                          append-icon="mdi-sync"
                          @click:append="generateRandomCode"
                          :error="errorFields.includes('code')"
                          @input="code = $event.toUpperCase()"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          inset
                          v-model="autoApply"
                          hide-details
                          label="Le client doit saisir manuellement le coupon"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <br />

                <div class="d-flex justify-space-between">
                  <v-spacer />
                  <v-btn color="primary" @click="e1 = 2"> Continuer </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-alert type="info">
                  <strong>Choisissons maintenant le type de remise.</strong>
                </v-alert>

                <v-card tile flat>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <h3>Type de remise</h3>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          La remise peut être en euro ou en pourcentage. <br />

                          Dans le cas où plusieurs prestations sont dans le
                          panier du client et que ce code promo est une
                          réduction en pourcentage, seul les prestations
                          autorisées avec ce code promo recevront la réduction,
                          les autres prestations seront au tarif normal.
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <v-radio-group v-model="reductionType" row>
                          <v-radio label="Valeur fixe" value="value" />
                          <v-radio label="Pourcentage" value="percentage" />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card tile flat>
                  <v-card-text>
                    <v-row dense v-if="reductionType === 'value'">
                      <v-col cols="12">
                        <v-text-field
                          type="number"
                          step="0.01"
                          outlined
                          v-model="amount"
                          hide-details
                          label="Valeur de la remise"
                          :error="errorFields.includes('amount')"
                          prepend-inner-icon="mdi-currency-eur"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense v-else-if="reductionType === 'percentage'">
                      <v-col cols="12">
                        <v-text-field
                          type="number"
                          step="1"
                          max="100"
                          outlined
                          v-model="amount"
                          hide-details
                          label="Valeur de la remise"
                          prepend-inner-icon="mdi-percent"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <br />

                <div class="d-flex justify-space-between">
                  <v-btn text @click="e1 = 1"> Retour </v-btn>
                  <v-spacer />
                  <v-btn color="primary" @click="e1 = 3"> Continuer </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-alert type="info">
                  <strong>A quel moment ce code peut être appliqué ?</strong>
                </v-alert>

                <v-alert type="error" v-if="errorFields.includes('conflict')">
                  Ces dates sont invalides car un autre coupon rentre dans
                  conflit dans une même boutique et une même prestation.
                </v-alert>

                <v-card tile flat>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <h3>Date de validité</h3>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          Indiquez ici les moments où le client pourra
                          bénéficier de cette réduction.
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          type="date"
                          outlined
                          v-model="couponStartDate"
                          hide-details
                          label="Date de début de validité"
                          :error="errorFields.includes('couponStartDate')"
                          :max="couponEndDate"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          type="date"
                          outlined
                          v-model="couponEndDate"
                          hide-details
                          label="Date de fin de validité"
                          :error="errorFields.includes('couponEndDate')"
                          :min="couponStartDate"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          v-model="addHours"
                          label="Préciser une heure"
                          inset
                        />
                      </v-col>
                      <v-col cols="6" v-if="addHours">
                        <v-text-field
                          type="time"
                          outlined
                          v-model="couponStartHour"
                          hide-details
                          label="Heure de début de validité"
                          :error="errorFields.includes('couponStartHour')"
                        />
                      </v-col>
                      <v-col cols="6" v-if="addHours">
                        <v-text-field
                          type="time"
                          outlined
                          v-model="couponEndHour"
                          hide-details
                          label="Heure de fin de validité"
                          :error="errorFields.includes('couponEndHour')"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <br />

                <div class="d-flex justify-space-between">
                  <v-btn text @click="e1 = 2"> Retour </v-btn>
                  <v-spacer />
                  <v-btn color="primary" @click="e1 = 4"> Continuer </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-alert type="info">
                  <strong>
                    Vous y êtes presque ! Sous quelles condition ce code peut
                    être appliqué ?
                  </strong>
                </v-alert>

                <v-card tile flat>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <h3>Conditions</h3>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          Indiquez ici le minimum de commande (en euros) à avoir
                          pour pouvoir bénéficier de cette promotion.
                        </p>
                      </v-col>
                      <!--                      <v-col cols="12">-->
                      <!--                        <v-switch-->
                      <!--                          inset-->
                      <!--                          v-model="newCustomer"-->
                      <!--                          label="Code applicable uniquement pour les nouveaux clients"-->
                      <!--                        />-->
                      <!--                      </v-col>-->
                      <v-col cols="12">
                        <v-switch
                          v-model="addMin"
                          label="Gérer le minimum de commande"
                          inset
                        />
                      </v-col>
                      <v-col cols="12" v-if="addMin">
                        <v-text-field
                          type="number"
                          step="0.01"
                          outlined
                          v-model="minAmount"
                          hide-details
                          label="Minimum de commande (€)"
                          :error="errorFields.includes('minAmount')"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <br />

                <div class="d-flex justify-space-between">
                  <v-btn text @click="e1 = 3"> Retour </v-btn>
                  <v-spacer />
                  <v-btn color="primary" @click="e1 = 5"> Continuer </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="5">
                <v-alert type="info">
                  <strong>Que se passe-t-il en cas d'annulation ?</strong>
                </v-alert>

                <v-card tile flat>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <h3>Annulation de réservation</h3>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          Ces paramètres sont utilisés lorsqu'un client souhaite
                          modifier ou se faire rembourser une réservation où ce
                          code cadeau a été utilisé.
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          inset
                          v-model="editDisabled"
                          label="Désactiver la modification du RDV"
                        />
                        <v-alert outlined dense>
                          Si cette option est cochée, les réservations
                          effectuées avec ce code promotionnel ne pourront pas
                          être remises au crédit par les clients depuis leur
                          compte.
                        </v-alert>
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          inset
                          v-model="refundDisabled"
                          label="Désactiver le remboursement du RDV"
                        />
                        <v-alert outlined dense>
                          Si cette option est cochée, les réservations
                          effectuées avec ce code promotionnel ne pourront pas
                          être remboursées par les clients depuis leur compte.
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <br />

                <div class="d-flex justify-space-between">
                  <v-btn text @click="e1 = 4"> Retour </v-btn>
                  <v-spacer />
                  <v-btn color="primary" @click="e1 = 6"> Continuer </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="6">
                <v-alert type="info">
                  <strong>A quels endroits peut-on utiliser ce code ?</strong>
                </v-alert>

                <v-card tile flat>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <h3>Rayon d'application</h3>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          Ce code cadeau peut être appliqué sur l'entièreté du
                          site de réservation, ou sur une partie seulement.
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <v-radio-group v-model="rayon" row>
                          <v-radio label="Tout le site" value="all" />
                          <v-radio label="Groupements" value="groupment" />
                          <v-radio
                            label="GéoGroupements"
                            value="geogroupment"
                          />
                          <v-radio label="Boutiques" value="shop" />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card tile flat>
                  <v-card-text>
                    <v-row dense v-if="rayon === 'all'">
                      <v-col cols="12">
                        <h3>Tout le site</h3>
                      </v-col>
                      <v-col cols="12">
                        <p>Le coupon pourra être appliqué sur tout le site.</p>
                      </v-col>
                    </v-row>
                    <v-row dense v-else-if="rayon === 'groupment'">
                      <v-col cols="12">
                        <div class="d-flex">
                          <h3>Groupements</h3>
                          <v-btn
                            text
                            @click="selectAllGroupments(true)"
                            x-small
                            class="ml-4"
                          >
                            Tous
                          </v-btn>
                          <v-btn
                            text
                            @click="selectAllGroupments(false)"
                            x-small
                          >
                            Aucun
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="search"
                          hide-details
                          outlined
                          prepend-inner-icon="mdi-magnify"
                          label="Rechercher un groupement"
                          dense
                          class="mb-6"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="max-height: 400px; overflow-y: auto"
                      >
                        <v-row dense>
                          <v-col
                            cols="12"
                            sm="6"
                            v-for="groupment in filteredGroupments"
                            :key="groupment.id"
                          >
                            <div class="d-flex align-center">
                              <v-switch
                                inset
                                class="my-0 mr-2"
                                hide-details
                                dense
                                v-model="couponGroupments"
                                :value="groupment.id"
                              />
                              <div>{{ groupment.name }}</div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row dense v-else-if="rayon === 'geogroupment'">
                      <v-col cols="12">
                        <div class="d-flex">
                          <h3>GéoGroupements</h3>
                          <v-btn
                            text
                            @click="selectAllGeoGroupments(true)"
                            x-small
                            class="ml-4"
                          >
                            Tous
                          </v-btn>
                          <v-btn
                            text
                            @click="selectAllGeoGroupments(false)"
                            x-small
                          >
                            Aucun
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="search"
                          hide-details
                          outlined
                          prepend-inner-icon="mdi-magnify"
                          label="Rechercher un géogroupement"
                          dense
                          class="mb-6"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="max-height: 400px; overflow-y: auto"
                      >
                        <v-row dense>
                          <v-col
                            cols="12"
                            sm="6"
                            v-for="geogroupment in filteredGeoGroupments"
                            :key="geogroupment.id"
                          >
                            <div class="d-flex align-center">
                              <v-switch
                                inset
                                class="my-0 mr-2"
                                hide-details
                                dense
                                v-model="couponGeoGroupments"
                                :value="geogroupment.id"
                              />
                              <div>{{ geogroupment.name.fr }}</div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row dense v-else-if="rayon === 'shop'">
                      <v-col cols="12">
                        <h3>Boutiques</h3>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="search"
                          hide-details
                          outlined
                          prepend-inner-icon="mdi-magnify"
                          label="Rechercher une boutique"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="
                          max-height: 400px;
                          overflow-y: auto;
                          overflow-x: hidden;
                        "
                      >
                        <div class="mt-5">
                          <v-btn
                            elevation="0"
                            @click="selectAllShopsFromGrp(null, true)"
                            x-small
                          >
                            Sélectionner tout
                          </v-btn>
                          <v-btn
                            elevation="0"
                            @click="selectAllShopsFromGrp(null, false)"
                            x-small
                          >
                            Sélectionner aucun
                          </v-btn>
                        </div>
                        <v-row dense class="mt-5">
                          <v-col
                            cols="12"
                            v-for="grp in filteredShopsByGroupment"
                            :key="grp.id"
                          >
                            <div class="mb-6">
                              <div class="mb-2">
                                <strong>{{ grp.name }}</strong>
                                <v-btn
                                  text
                                  @click="selectAllShopsFromGrp(grp.id, true)"
                                  x-small
                                  class="ml-4"
                                >
                                  Tous
                                </v-btn>
                                <v-btn
                                  text
                                  @click="selectAllShopsFromGrp(grp.id, false)"
                                  x-small
                                >
                                  Aucun
                                </v-btn>
                              </div>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  class="d-flex align-center"
                                  v-for="shop in grp.shops"
                                  :key="shop.abId"
                                >
                                  <v-switch
                                    inset
                                    class="my-0 mr-2"
                                    hide-details
                                    dense
                                    v-model="couponShops"
                                    :value="shop.abId"
                                  />
                                  <div>{{ shop.name }}</div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <br />

                <div class="d-flex justify-space-between">
                  <v-btn text @click="e1 = 5"> Retour </v-btn>
                  <v-spacer />
                  <v-btn color="primary" @click="e1 = 7"> Continuer </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="7">
                <v-alert type="info">
                  <strong
                    >C'est la dernière étape ! Quelles prestations sont
                    concernées par cette réduction ?</strong
                  >
                </v-alert>

                <v-card tile flat>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <h3>Services concernés</h3>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          Indiquez ici les services où cette promotion pourra
                          être appliquée.
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="searchService"
                          hide-details
                          outlined
                          prepend-inner-icon="mdi-magnify"
                          label="Rechercher un service"
                          dense
                          class="mb-6"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="max-height: 400px; overflow-y: auto"
                      >
                        <div class="my-5">
                          <v-btn
                            elevation="0"
                            @click="selectAllServicesFromFamily(null, true)"
                            x-small
                          >
                            Sélectionner tout
                          </v-btn>
                          <v-btn
                            elevation="0"
                            @click="selectAllServicesFromFamily(null, false)"
                            x-small
                          >
                            Sélectionner aucun
                          </v-btn>
                        </div>

                        <v-row dense>
                          <v-col
                            cols="12"
                            v-for="family in filteredServicesByFamily"
                            :key="family.name || 'other'"
                          >
                            <div class="mb-6">
                              <div class="mb-2">
                                <strong>{{ family.name || 'AUTRE' }}</strong>
                                <v-btn
                                  text
                                  @click="
                                    selectAllServicesFromFamily(
                                      family.name,
                                      true
                                    )
                                  "
                                  x-small
                                  class="ml-4"
                                >
                                  Tous
                                </v-btn>
                                <v-btn
                                  text
                                  @click="
                                    selectAllServicesFromFamily(
                                      family.name,
                                      false
                                    )
                                  "
                                  x-small
                                >
                                  Aucun
                                </v-btn>
                              </div>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  class="d-flex align-center"
                                  v-for="service in family.services"
                                  :key="service.id"
                                >
                                  <v-switch
                                    inset
                                    class="my-0 mr-2"
                                    hide-details
                                    dense
                                    v-model="couponServices"
                                    :value="service.id"
                                  />
                                  <div>{{ service.name.fr }}</div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <br />

                <div class="d-flex justify-space-between">
                  <v-btn text @click="e1 = 6"> Retour </v-btn>
                  <v-spacer />
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
        <v-col cols="12" md="3">
          <v-card>
            <v-card-title> Récapitulatif </v-card-title>
            <v-card-text>
              <div v-if="code">
                <v-row>
                  <v-col cols="6">
                    <strong>Code : </strong>
                  </v-col>
                  <v-col cols="6">
                    {{ code }}
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="6">
                    <strong>Code : </strong>
                  </v-col>
                  <v-col cols="6">
                    <span class="red--text">A définir</span>
                    <v-btn x-small icon @click="e1 = 1">
                      <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <br />
              <div>
                <v-row>
                  <v-col cols="6">
                    <strong>Automatique :</strong>
                  </v-col>
                  <v-col cols="6">
                    {{ !autoApply ? 'Oui' : 'Non' }}
                  </v-col>
                </v-row>
              </div>
              <br />
              <div v-if="reductionType && amount">
                <v-row>
                  <v-col cols="6">
                    <strong>Type de remise :</strong>
                  </v-col>
                  <v-col cols="6">
                    {{ reductionType === 'value' ? 'Valeur' : 'Pourcentage' }}
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="6">
                    <strong>Type de remise : </strong>
                  </v-col>
                  <v-col cols="6">
                    <span class="red--text">A définir</span>
                    <v-btn x-small icon @click="e1 = 2">
                      <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <br />
              <div v-if="amount">
                <v-row>
                  <v-col cols="6">
                    <strong>Valeur de remise :</strong>
                  </v-col>
                  <v-col cols="6">
                    {{
                      reductionType === 'value' ? amount + '€' : amount + '%'
                    }}
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="6">
                    <strong>Valeur de remise : </strong>
                  </v-col>
                  <v-col cols="6">
                    <span class="red--text">A définir</span>
                    <v-btn x-small icon @click="e1 = 2">
                      <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <br />
              <div v-if="couponStartDate && couponEndDate">
                <v-row>
                  <v-col cols="6">
                    <strong>Date de validité :</strong>
                  </v-col>
                  <v-col cols="6">
                    {{
                      dayjs(couponStartDate).format('DD/MM/YY') +
                      ' à ' +
                      dayjs(couponEndDate).format('DD/MM/YY')
                    }}
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="6">
                    <strong>Date de validité : </strong>
                  </v-col>
                  <v-col cols="6">
                    <span class="red--text">A définir</span>
                    <v-btn x-small icon @click="e1 = 3">
                      <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <br />
              <div v-if="couponStartHour && couponEndHour && addHours">
                <v-row>
                  <v-col cols="6">
                    <strong>Heure de validité :</strong>
                  </v-col>
                  <v-col cols="6">
                    {{ couponStartHour + ' à ' + couponEndHour }}
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="addHours">
                <v-row>
                  <v-col cols="6">
                    <strong>Heure de validité : </strong>
                  </v-col>
                  <v-col cols="6">
                    <span class="red--text">A définir</span>
                    <v-btn x-small icon @click="e1 = 3">
                      <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <br v-if="addHours" />
              <div>
                <v-row>
                  <v-col cols="6">
                    <strong>Montant minimum :</strong>
                  </v-col>
                  <v-col cols="6">
                    {{ minAmount ? minAmount + '€' : 'Aucun' }}
                  </v-col>
                </v-row>
              </div>
              <br />
              <div>
                <v-row>
                  <v-col cols="6">
                    <strong>Annulation RDV : </strong>
                  </v-col>
                  <v-col cols="6">
                    <span
                      :style="
                        'color:' +
                        (editDisabled
                          ? 'orange !important;'
                          : 'green !important;')
                      "
                      >{{ editDisabled ? 'Non autorisé' : 'Autorisé' }}</span
                    >
                  </v-col>
                </v-row>
              </div>
              <br />
              <div>
                <v-row>
                  <v-col cols="6">
                    <strong>Remboursement RDV : </strong>
                  </v-col>
                  <v-col cols="6">
                    <span
                      :style="
                        'color:' +
                        (refundDisabled
                          ? 'orange !important;'
                          : 'green !important;')
                      "
                      >{{ refundDisabled ? 'Non autorisé' : 'Autorisé' }}</span
                    >
                  </v-col>
                </v-row>
              </div>
              <br />
              <div>
                <div v-if="rayon === 'all'">
                  <v-row>
                    <v-col cols="6">
                      <strong>Application : </strong>
                    </v-col>
                    <v-col cols="6"> Tout le site </v-col>
                  </v-row>
                </div>
                <div v-else-if="rayon === 'groupment'">
                  <v-row>
                    <v-col cols="6">
                      <strong>Groupements : </strong>
                    </v-col>
                    <v-col cols="6">
                      <span v-if="couponGroupments.length">
                        {{ couponGroupments.length }} groupement(s)
                      </span>
                      <span v-else>
                        <span class="red--text">A définir</span>
                        <v-btn x-small icon @click="e1 = 6">
                          <v-icon small>mdi-arrow-right</v-icon>
                        </v-btn>
                      </span>
                    </v-col>
                  </v-row>
                </div>
                <div v-else-if="rayon === 'shop'">
                  <v-row>
                    <v-col cols="6">
                      <strong>Boutiques : </strong>
                    </v-col>
                    <v-col cols="6">
                      <span v-if="couponShops.length">
                        {{ couponShops.length }} boutique(s)
                      </span>
                      <span v-else>
                        <span class="red--text">A définir</span>
                        <v-btn x-small icon @click="e1 = 6">
                          <v-icon small>mdi-arrow-right</v-icon>
                        </v-btn>
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <br />
              <div v-if="couponServices.length">
                <v-row>
                  <v-col cols="6">
                    <strong>Services : </strong>
                  </v-col>
                  <v-col cols="6">
                    <span>{{ couponServices.length }} service(s)</span>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col cols="6">
                    <strong>Services : </strong>
                  </v-col>
                  <v-col cols="6">
                    <span class="red--text">A définir</span>
                    <v-btn x-small icon @click="e1 = 7">
                      <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>

          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
            class="mt-3"
            block
          >
            Terminer
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import dayjs from 'dayjs'

export default {
  components: {},
  props: {
    coupon: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      e1: 1,
      search: '',
      searchService: '',
      code: '',
      autoApply: true,
      newCustomer: false,
      addMin: false,
      minAmount: 0,
      maxAmount: 0,
      couponStartDate: '',
      couponEndDate: '',
      addHours: false,
      couponStartHour: '',
      couponEndHour: '',
      rayon: 'all',
      couponGroupments: [],
      couponGeoGroupments: [],
      couponShops: [],
      couponServices: [],
      reductionType: 'value',
      amount: 0,
      editDisabled: false,
      refundDisabled: false,
      skipWatch: false,
      error: false,
      loading: false,

      errorFields: [],
    }
  },
  created() {
    if (this.coupon) {
      this.skipWatch = true

      this.code = this.coupon.code
      this.autoApply = this.coupon.autoApply
      this.newCustomer = this.coupon.newCustomer
      this.minAmount = parseFloat(parseFloat(this.coupon.minAmount).toFixed(2))
      this.maxAmount = parseFloat(parseFloat(this.coupon.maxAmount).toFixed(2))
      this.couponStartDate = dayjs(this.coupon.couponStartDate).format(
        'YYYY-MM-DD'
      )
      this.couponEndDate = dayjs(this.coupon.couponEndDate).format('YYYY-MM-DD')
      this.couponStartHour = this.coupon.couponStartHour
      this.couponEndHour = this.coupon.couponEndHour
      this.rayon = this.coupon.groupments.length
        ? 'groupment'
        : this.coupon.geoGroupments.length
        ? 'geogroupment'
        : this.coupon.shops.length
        ? 'shop'
        : 'all'
      this.couponGroupments = this.coupon.groupments || []
      this.couponGeoGroupments = this.coupon.geoGroupments || []
      this.couponShops = this.coupon.shops || []
      this.couponServices = this.coupon.services || []
      this.reductionType = this.coupon.reductionType
      this.amount = this.coupon.amount
      this.editDisabled = this.coupon.editDisabled
      this.refundDisabled = this.coupon.refundDisabled
      this.addHours =
        this.coupon.couponStartHour !== '00:00' ||
        this.coupon.couponEndHour !== '23:59'
    }
  },
  computed: {
    ...mapGetters({
      geogroupments: 'geogroupments/getGeogroupments',
    }),
    abGroupments() {
      return this.shops.reduce((acc, cur) => {
        const index = acc.findIndex((grp) => grp.id === cur.grp.id)

        if (index === -1) {
          acc.push(cur.grp)
        }

        return acc
      }, [])
    },
    shops() {
      return this.$store.getters['shop/fusionShop'](this.shopsFiltered)
    },
    services() {
      return this.$store.getters['articles/fusionArticle']().filter((a) =>
        [1, 4].includes(parseInt(a.art_type))
      )
    },
    filteredGroupments() {
      return this.abGroupments.filter((g) => {
        const search = this.search.toLowerCase().trim()
        const name = (g.name || '').toLowerCase().trim()

        if (!search) {
          return true
        }

        if (name.includes(search)) {
          return true
        }

        return false
      })
    },
    filteredGeoGroupments() {
      return this.geogroupments.filter((g) => {
        const search = this.search.toLowerCase().trim()
        const name = (g.name.fr || '').toLowerCase().trim()

        if (!search) {
          return true
        }

        if (name.includes(search)) {
          return true
        }

        return false
      })
    },
    filteredShops() {
      return this.shops
        .filter((s) => {
          const search = this.search.toLowerCase().trim()
          const name = (s.name || '').toLowerCase().trim()

          if (!search) {
            return true
          }

          if (name.includes(search)) {
            return true
          }

          return false
        })
        .filter((s) => !!s.id)
    },
    shopsByGroupment() {
      return this.shops.reduce((acc, cur) => {
        const grpIndex = acc.findIndex(
          (g) => g.id.toString() === (cur.grp ? cur.grp.id.toString() : -1)
        )

        if (grpIndex === -1) {
          acc.push({
            id: cur.grp.id,
            name: cur.grp.name,
            shops: [cur],
          })
        } else {
          acc[grpIndex].shops.push(cur)
        }

        return acc
      }, [])
    },
    filteredShopsByGroupment() {
      return this.shops
        .filter((s) => {
          const search = this.search.toLowerCase().trim()
          const name = (s.name || '').toLowerCase().trim()

          if (!search) {
            return true
          }

          if (name.includes(search)) {
            return true
          }

          return false
        })
        .filter((s) => !!s.id)
        .reduce((acc, cur) => {
          const grpIndex = acc.findIndex(
            (g) => g.id.toString() === (cur.grp ? cur.grp.id.toString() : -1)
          )

          if (grpIndex === -1) {
            acc.push({
              id: cur.grp.id,
              name: cur.grp.name,
              shops: [cur],
            })
          } else {
            acc[grpIndex].shops.push(cur)
          }

          return acc
        }, [])
    },
    filteredServices() {
      return this.services
        .filter((s) => !!s.id)
        .filter((s) => {
          const search = this.searchService.toLowerCase().trim()
          const name = ((s.name ? s.name.fr : null) || '').toLowerCase().trim()

          if (!search) {
            return true
          }

          if (name.includes(search)) {
            return true
          }

          return false
        })
    },
    filteredServicesByFamily() {
      return this.filteredServices.reduce((acc, cur) => {
        const grpIndex = acc.findIndex((g) => g.name.toString() === cur.family)

        if (grpIndex === -1) {
          acc.push({
            name: cur.family,
            services: [cur],
          })
        } else {
          acc[grpIndex].services.push(cur)
        }

        return acc
      }, [])
    },
    servicesByFamily() {
      return this.services
        .filter((s) => !!s.id)
        .reduce((acc, cur) => {
          const grpIndex = acc.findIndex(
            (g) => g.name.toString() === cur.family
          )

          if (grpIndex === -1) {
            acc.push({
              name: cur.family,
              services: [cur],
            })
          } else {
            acc[grpIndex].services.push(cur)
          }

          return acc
        }, [])
    },
  },
  watch: {
    couponGroupments(n, o) {
      n.forEach((ng) => {
        if (!o.map((og) => og).includes(ng)) {
          this.shops.forEach((s) => {
            if (s.grp.id === ng && !this.couponShops.includes(s.abId)) {
              this.couponShops.push(s.abId)
            }
          })
        }
      })

      o.forEach((og) => {
        if (!n.map((ng) => ng).includes(og)) {
          this.shops.forEach((s) => {
            if (s.grp.id === og && this.couponShops.includes(s.abId)) {
              this.couponShops = this.couponShops.filter((ss) => ss !== s.abId)
            }
          })
        }
      })
    },
    couponGeoGroupments(n, o) {
      n.forEach((ng) => {
        if (!o.map((og) => og).includes(ng)) {
          this.shops.forEach((s) => {
            if (
              s.geoGroupment &&
              s.geoGroupment.id === ng &&
              !this.couponShops.includes(s.abId)
            ) {
              this.couponShops.push(s.abId)
            }
          })
        }
      })

      o.forEach((og) => {
        if (!n.map((ng) => ng).includes(og)) {
          this.shops.forEach((s) => {
            if (
              s.geoGroupment &&
              s.geoGroupment.id === og &&
              this.couponShops.includes(s.abId)
            ) {
              this.couponShops = this.couponShops.filter((ss) => ss !== s.abId)
            }
          })
        }
      })
    },
    rayon() {
      if (this.skipWatch) {
        this.skipWatch = false
        return
      }
      this.couponGroupments = []
      this.couponGeoGroupments = []
      this.couponShops = []

      if (this.rayon === 'all') {
        this.shops.forEach((s) => {
          this.couponShops.push(s.abId)
        })
      }
    },
  },
  async mounted() {
    if (this.coupon) {
      this.autoApply = !this.coupon.autoApply
    }

    try {
      await this.$store.dispatch('geogroupments/fetchGeogroupments', {})
    } catch (err) {
      console.log('err :>> ', err)
    }
  },
  methods: {
    dayjs,
    async handleSubmitForm() {
      this.error = false
      this.errorFields = []
      this.loading = true

      if (!this.code) {
        this.errorFields.push('code')
      }

      if (this.minAmount < 0) {
        this.errorFields.push('minAmount')
      }

      if (this.amount <= 0) {
        this.errorFields.push('amount')
      }

      if (this.reductionType === 'percentage' && this.amount > 100) {
        this.errorFields.push('amount')
      }

      if (!this.couponStartDate) {
        this.errorFields.push('couponStartDate')
      }

      if (!this.couponEndDate) {
        this.errorFields.push('couponEndDate')
      }

      if (this.addHours && !this.couponStartHour) {
        this.errorFields.push('couponStartHour')
      }

      if (this.addHours && !this.couponEndHour) {
        this.errorFields.push('couponEndHour')
      }

      if (this.rayon !== 'all' && this.couponServices.length === 0) {
        this.errorFields.push('rayon')
      }

      try {
        const couponsConflicts = (
          await MKPSDK.Coupons.getConflicts(
            this.couponStartDate + ' ' + this.couponStartHour + ':00',
            this.couponEndDate + ' ' + this.couponEndHour + ':00',
            !this.autoApply
          )
        ).coupons
          .filter((c) => {
            let cShop = false
            let cPresta = false

            c.shops.forEach((cs) => {
              if (this.couponShops.includes(cs)) {
                cShop = true
              }
            })

            if (!c.shops.length) {
              cShop = true
            }

            c.services.forEach((cs) => {
              if (this.couponServices.includes(cs)) {
                cPresta = true
              }
            })

            if (!c.services.length) {
              cPresta = true
            }

            return cShop && cPresta
          })
          .filter((c) => {
            return this.coupon ? c.id !== this.coupon.id : true
          })

        if (couponsConflicts.length) {
          this.errorFields.push('conflict')
        }
      } catch (e) {
        console.log(e)

        this.errorFields.push('conflict')
      }

      if (this.errorFields.length) {
        this.error = true
        this.loading = false
        this.e1 = 1

        window.scrollTo({ top: 0, behavior: 'smooth' })

        return
      }

      if (!this.addHours) {
        this.couponStartHour = '00:00'
        this.couponEndHour = '23:59'
      }
      if (!this.addMin) {
        this.minAmount = 0
      }

      try {
        if (this.coupon) {
          await MKPSDK.Coupons.updateCoupon(this.coupon.id, {
            code: this.code,
            autoApply: !this.autoApply,
            newCustomer: this.newCustomer,
            firstOrder: this.newCustomer,
            minAmount: this.minAmount * 100,
            maxAmount: this.maxAmount * 100,
            couponStartDate: this.couponStartDate,
            couponEndDate: this.couponEndDate,
            couponStartHour: this.couponStartHour,
            couponEndHour: this.couponEndHour,
            groupments: this.couponGroupments,
            geoGroupments: this.couponGeoGroupments,
            shops: this.couponShops,
            services: this.couponServices,
            amount: this.amount,
            reductionType: this.reductionType,
            editDisabled: this.editDisabled,
            refundDisabled: this.refundDisabled,
          })
        } else {
          await MKPSDK.Coupons.createCoupon({
            code: this.code,
            autoApply: !this.autoApply,
            newCustomer: this.newCustomer,
            firstOrder: this.newCustomer,
            minAmount: this.minAmount * 100,
            maxAmount: this.maxAmount * 100,
            couponStartDate: this.couponStartDate,
            couponEndDate: this.couponEndDate,
            couponStartHour: this.couponStartHour,
            couponEndHour: this.couponEndHour,
            groupments: this.couponGroupments,
            geoGroupments: this.couponGeoGroupments,
            shops: this.couponShops,
            services: this.couponServices,
            amount: this.amount,
            reductionType: this.reductionType,
            editDisabled: this.editDisabled,
            refundDisabled: this.refundDisabled,
          })
        }

        await this.$router.push('/coupons')
      } catch (e) {
        console.log(e)

        if (e.response) {
          this.errorFields = e.response.data.details.body.flatMap((b) => b.path)
        }

        this.error = true
        this.loading = false
        this.e1 = 1

        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    selectAllGroupments(val) {
      this.abGroupments.forEach((grp) => {
        if (val) {
          if (!this.couponGroupments.includes(grp.id)) {
            this.couponGroupments.push(grp.id)
          }
        } else {
          this.couponGroupments = []
        }
      })
    },
    selectAllGeoGroupments(val) {
      this.geogroupments.forEach((grp) => {
        if (val) {
          if (!this.couponGeoGroupments.includes(grp.id)) {
            this.couponGeoGroupments.push(grp.id)
          }
        } else {
          this.couponGeoGroupments = []
        }
      })
    },
    selectAllShopsFromGrp(grpId, val) {
      if (grpId) {
        const grp = this.shopsByGroupment.find(
          (g) => g.id.toString() === grpId.toString()
        )

        if (!val) {
          this.couponShops = this.couponShops.filter(
            (sAbId) =>
              !grp.shops
                .map((sss) => sss.abId.toString())
                .includes(sAbId.toString())
          )
        } else {
          grp.shops.forEach((s) => {
            if (
              this.couponShops.findIndex(
                (sAbId) => sAbId.toString() === s.abId.toString()
              ) === -1
            ) {
              this.couponShops.push(s.abId)
            }
          })
        }
      } else {
        if (!val) {
          this.couponShops = []
        } else {
          this.shops.forEach((s) => {
            if (
              this.couponShops.findIndex(
                (sAbId) => sAbId.toString() === s.abId.toString()
              ) === -1
            ) {
              this.couponShops.push(s.abId)
            }
          })
        }
      }
    },
    selectAllServicesFromFamily(familyName, val) {
      if (familyName) {
        const grp = this.servicesByFamily.find((g) => g.name === familyName)

        if (!val) {
          this.couponServices = this.couponServices.filter(
            (serviceId) =>
              !grp.services
                .map((ser) => ser.id.toString())
                .includes(serviceId.toString())
          )
        } else {
          grp.services.forEach((ser) => {
            if (
              this.couponServices.findIndex(
                (serviceId) => serviceId.toString() === ser.id.toString()
              ) === -1
            ) {
              this.couponServices.push(ser.id)
            }
          })
        }
      } else {
        if (!val) {
          this.couponServices = []
        } else {
          this.filteredServices.forEach((ser) => {
            if (
              this.couponServices.findIndex(
                (serviceId) => serviceId.toString() === ser.id.toString()
              ) === -1
            ) {
              this.couponServices.push(ser.id)
            }
          })
        }
      }
    },
    generateRandomCode() {
      const str = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'
      const length = 8

      this.code = str
        .split('')
        .sort(() => 0.5 - Math.random())
        .join('')
        .substr(0, length)
    },
  },
}
</script>

<style scoped></style>
